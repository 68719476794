<template>
  <div class="content_index">
    <!-- 背景块 -->
    <img src="../static/vrbg.png" alt="" srcset="" />
    <!-- 内容块 -->
    <div class="content_body">
      <!-- 标题 -->
      <div class="study_title">
        <div class="title">VR实训</div>
      </div>
      <!-- 小Tab -->
      <div class="menu_top_content">
        <img
          src="../static/fh.png"
          alt=""
          class="base_back_image"
          @click="handleBack"
        />
        <!-- <div
          class="menu_top_left"
          @click="curr_index = 0"
          :style="curr_index === 0 ? 'background:#fff' : ''"
        >
          <div :style="curr_index === 0 ? 'color: #e81c1c' : ''">
            {{ left }}
          </div>
        </div> -->
        <!-- <div
          class="menu_top_right"
          @click="curr_index = 1"
          :style="curr_index === 1 ? 'background:#fff' : ''"
        >
          <div :style="curr_index === 1 ? 'color: #e81c1c' : ''">
            {{ right }}
          </div>
        </div> -->
      </div>
      <!-- 内容列表 -->
      <div class="content_list">
        <div class="item" v-for="(item, index) in SortTypeList" :key="index">
          <div class="item_images">
            <img :src="handleGetUrl(item.poster_image)" alt="" />
          </div>
          <div class="item_content">
            <div class="app_name">{{ item.name }}</div>
            <div class="app_desc">
              {{ item.description }}
            </div>
            <div class="app_size">使用次数: {{ item.count }}</div>
            <div class="app_open" @click="handleOpen(item)">
              <div>
                <img src="@/assets/bf.png" alt="" />
              </div>
              <div class="open_text">打开</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getArticleList } from '../api/index'
import { baseURL } from '../request/index'
export default {
  // name: 'HomeView',
  data() {
    return {
      left: '',
      right: '',
      curr_index: 0, // 0 安全分类 1 质量分类
      SortTypeList: [],
    }
  },
  components: {},
  mounted() {
    this.handleGetList()
  },
  computed: {
    handleGetUrl() {
      return function (url) {
        return baseURL + url
      }
    },
    // handleGetType() {
    //   return function (type) {
    //     if (this.SortTypeList.length === 2) {
    //       if (type === 'left') {
    //         return this.SortTypeList[0].children
    //       }
    //       if (type === 'right') {
    //         return this.SortTypeList[1].children
    //       }
    //     }
    //     return []
    //   }
    // },
  },
  methods: {
    handleBack() {
      history.back(-1)
    },
    async handleGetList() {
      const t = new FormData()
      t.append('type', 'vr')
      // t.append('istree', '1')
      const res = await getArticleList(t)
      const list = res.data.list || []
      if (list.length > 0) {
        this.SortTypeList = list
      } else {
        alert('结构出错')
      }
    },
    handleGoToInfo(item) {
      this.$router.push(`/study_info?id=` + item.id)
    },
    handleOpen(item) {
      const _this = this
      if (this.isReturn) {
        return
      }
      if (item.outlink) {
        this.isReturn = true
        // window.open(item.outlink)
        location.href = item.outlink
        setTimeout(() => {
          _this.isReturn = false
        }, 2000)
        return
      }
      if (item.tolink) {
        // window.open(item.tolink)
        this.isReturn = true
        location.href = item.tolink
        setTimeout(() => {
          _this.isReturn = false
        }, 2000)
        return
      }
      this.$router.push(`${item.tolink}?id=${item.id}`)
      // this.$router.push(`/courseware?id=` + item.id)
    },
  },
}
</script>
<style scoped lang="scss">
.content_index {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .content_body {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: transparent;
    }

    & > .study_title {
      height: 2rem;
      padding-top: 0.5rem;
      position: relative;

      & > .title {
        position: absolute;
        width: 100%;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 0.1rem;
        text-align: center;
        color: #fff;
      }

      & > img {
        margin-top: 0.5rem;
        width: 100%;
      }
    }

    & > .menu_top_content {
      width: 90%;
      height: 1.4rem;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin: 0 auto;
      // background-color: rgba(255, 255, 255, 0.4);
      // border-radius: 1rem;
      // padding: 0.15rem;
      // box-sizing: border-box;

      & > .menu_top_left {
        width: 49%;
        border-radius: 1rem;
        font-weight: bold;
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1rem;
        & > .img_top {
          display: flex;
          justify-content: center;
        }
      }

      & > .menu_top_right {
        width: 49%;
        border-radius: 1rem;
        font-weight: bold;
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1rem;

        & > .img_top {
          display: flex;
          justify-content: center;
        }

        & > .img_footer {
          position: relative;

          & > div {
            position: absolute;
            top: 35%;
            font-size: 0.4rem;
            right: 31%;
            color: rgb(18, 21, 45);
          }

          & > img {
            width: 100%;
          }
        }
      }
    }

    & > .content_list {
      padding: 0.3rem;

      & > .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        margin-bottom: 0.3rem;
        padding: 0.2rem;
        background-size: 100% 100% !important;
        background-color: #fff;
        border-radius: 0.3rem;
        // background: url('../static/images/href_right_base.png') no-repeat;
        height: 2rem;
        overflow: hidden;
        & > .item_images {
          width: 3rem;
          height: 2rem;
          overflow: hidden;

          & > img {
            width: 100%;
          }
        }

        & > .item_content {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding-left: 0.1rem;
          color: #333;

          & > .app_name {
            font-size: 0.4rem;
            font-weight: bold;
          }

          & > .app_desc {
            overflow: hidden;
            overflow-y: scroll;
            font-size: 0.2rem;
            line-height: 0.4rem;
            height: 1.8rem;
            padding-right: 1.5rem;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              background-color: transparent;
            }
          }

          & > .app_size {
            font-size: 0.2rem;
          }

          & > .app_open {
            position: absolute;
            right: 0.2rem;
            bottom: 0;
            font-size: 0.2rem;
            text-align: center;
            cursor: pointer;

            & > .open_text {
              margin-top: 0.1rem;
            }

            & img {
              width: 0.5rem;
            }
          }
        }
      }

      & > .title_content {
        color: rgb(34, 244, 247);
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 0.7rem;
        margin-bottom: 0.3rem;
        background-size: 100% 100% !important;
        background: url('../static/images/style_k.png') no-repeat;
      }
    }
  }
}

.base_back_image {
  position: absolute;
  left: 0.5rem;
  top: 0.9rem;
  width: 0.45rem;
  object-fit: contain;
  z-index: 10;
}
</style>
